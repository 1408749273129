/* Navbar.css */
.navbar {
  background-color: #ffa952;
  padding: 5rem;
  color: white;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: relative;
}

.navbar-logo {
  display: flex;
  align-items: center;
  margin: 0;
  position: absolute;
  left: 0;
}

.navbar-logo img {
  margin-right: 1rem;
}

.navbar-title {
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}

.mobile-menu-icon {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
}

.navbar-links {
  display: flex;
  list-style-type: none;
  margin: 0 auto;  /* This centers the menu */
  padding: 0;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navbar-links li {
  margin-left: 2rem;
}

.navbar-links a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
}

.navbar-links a:hover {
  color: gray;
}

.image-logo {
  width: 100px;
  height: 100px;
}

/* Mobile Menu */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    margin: auto;
  }

  .mobile-menu-icon {
    display: block;
  }

  .navbar-links.active {
    display: flex;
  }

  .image-logo {
    width: 50px;
    height: 50px;
  }
}
